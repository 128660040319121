import React, { Component, useEffect } from 'react'
import { APIService } from '../Api/APIService'
import logo from '../logo.svg'
import { Button, Dropdown, Image, Layout, Menu, MenuProps, Space, Spin, Typography, notification } from 'antd'
import { BrowserRouter as Router, Link, Route, RouteComponentProps, Switch } from 'react-router-dom'
import ProjectDetailLayout from './ProjectDetailLayout'
import StoreLayout from './StoreLayout'
import ServiceDetailLayout from './ServiceLayouts/ServiceDetailLayout'
import StoreServiceDetail from './StoreLayouts/StoreServiceDetail'
import BillingAccountList from './BillingAccountLayouts/BillingAccountList'
import BillingAccountDetail from './BillingAccountLayouts/BillingAccountDetail'
import { HomePage } from './HomePage'
import { ServiceList } from './ServiceLayouts/ServiceList'
import UserSettingsModal from '../Modals/UserSettingsModal'
import { topMenuStore } from '../Actions/Actions'
import { BulbOutlined, CreditCardOutlined, DownOutlined, LoadingOutlined, LogoutOutlined, SettingOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import { RetterCloudObject } from '@retter/sdk'
import { userStore } from '../Actions/Stores/UserStore'
import { UserProviderContext } from '../Contexts/UserProviderContext'
import { InviteUserModal } from '../Modals/InviteUserModal'
import conf from '../config/autoGenConfig'
import ChangeProjectSubscriptionModal from './AdminSettings/ChangeProjectSubscriptionModal'
import { Header } from 'antd/es/layout/layout'
import { ThemeContext } from '../Contexts/ThemeContext'
import { withAntdToken } from '../Components/with-antd-token-hoc'

const { Title } = Typography

interface Props {
    token: any
    apiService: APIService
    userInstance?: RetterCloudObject
}

interface State {
    showSettings: boolean
    topMenuTitleContent: React.ReactChild
    topMenuExtra: any[]
    userDetail?: any
    loading: boolean
    invitesVisible: boolean
    showChangeProjectSubscriptionSettings: boolean
}

class MainLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showSettings: false,
            topMenuTitleContent: '',
            topMenuExtra: [],
            loading: true,
            invitesVisible: false,
            showChangeProjectSubscriptionSettings: false,
        }
        this.handleLogoutClick = this.handleLogoutClick.bind(this)
        topMenuStore.subscribe(async () => {
            const state = topMenuStore.getState()
            if (state) {
                if (state.data.extraMenu) {
                    this.setState({
                        topMenuExtra: state.data.extraMenu || [],
                    })
                }
                if (state.data.title) {
                    this.setState({
                        topMenuTitleContent: state.data.title,
                    })
                }
            }
        })
    }

    componentDidMount() {
        userStore.subscribe(() => {
            const state = userStore.getState()
            this.setState({
                userDetail: state.data,
                loading: false,
            })
        })

        const checkSignleSignOn = async () => {
            try {
                // Get the query string from the URL
                const queryString = window.location.search

                // Parse the query string
                const queryParams = new URLSearchParams(queryString)

                if (queryParams.has('customToken')) {
                    this.setState({
                        loading: true,
                    })
                    const customToken = queryParams.get('customToken')
                    if (customToken) {
                        const res = await this.props.apiService.rootRbsSdk.authenticateWithCustomToken(customToken)
                        if (res.authStatus === 'SIGNED_IN') {
                            notification.success({
                                message: 'Login Successful',
                            })
                        } else if (res.authStatus === 'AUTH_FAILED') {
                            notification.error({
                                message: 'Login Failed',
                            })
                        }
                        window.history.replaceState({}, document.title, window.location.pathname)
                    }
                    this.setState({
                        loading: false,
                    })
                }
            } catch (e) {
                console.error(e)
                this.setState({
                    loading: false,
                })
            }
        }

        checkSignleSignOn()
    }

    async handleLogoutClick() {
        this.setState({
            loading: true,
        })
        await this.props.apiService.rootRbsSdk.signOut()
        localStorage.clear()
        this.setState({
            loading: false,
        })
        window.location.href = '/'
    }

    async handleServicesLink() {}

    showSettings = (val: boolean) => {
        this.setState({
            showSettings: val,
        })
    }

    showChangeProjectSubscriptionSettings = (val: boolean) => {
        this.setState({
            showChangeProjectSubscriptionSettings: val,
        })
    }

    settingsCancelCallback = () => {
        this.setState({
            showSettings: false,
        })
    }

    inviteModelClosed = () => {
        this.setState({
            invitesVisible: false,
        })
    }

    render() {
        return (
            <>
                <Layout style={{ minHeight: '100vh', background: this.props.token.colorBgLayout }}>
                    <Spin size={'large'} indicator={<Spin indicator={<LoadingOutlined spin />} />} spinning={this.state.loading}>
                        {/* @ts-ignore */}
                        <Router>
                            <Header
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 20px',
                                    background: this.props.token.colorBgContainer,
                                }}
                            >
                                <Link to="/">
                                    <Image preview={false} src={logo} height={20} />
                                </Link>
                                <div style={{ marginLeft: 25, marginTop: 15 }}>
                                    <Title level={5}>{this.state.topMenuTitleContent}</Title>
                                </div>
                                <Space style={{ marginLeft: 'auto' }}>
                                    {this.state.topMenuExtra}
                                    <UserProviderContext.Consumer>
                                        {ctx => (
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            type: 'group',
                                                            label: this.state.userDetail ? this.state.userDetail.email : 'loading',
                                                        },
                                                        {
                                                            type: 'divider',
                                                        },
                                                        {
                                                            style: {
                                                                color: 'red',
                                                            },
                                                            key: 'logout',
                                                            label: 'Logout',
                                                            itemIcon: <LogoutOutlined />,
                                                            onClick: this.handleLogoutClick,
                                                        },
                                                        {
                                                            key: 'settings',
                                                            label: 'Settings',
                                                            itemIcon: <SettingOutlined />,
                                                            onClick: () => {
                                                                this.showSettings(true)
                                                            },
                                                        },
                                                        ctx.isRootProjectMember
                                                            ? {
                                                                  key: 'changeProjectSubscription',
                                                                  label: 'Change Project Subscription',
                                                                  itemIcon: <CreditCardOutlined />,
                                                                  onClick: () => {
                                                                      this.showChangeProjectSubscriptionSettings(true)
                                                                  },
                                                              }
                                                            : null,
                                                        {
                                                            type: 'divider',
                                                        },
                                                        {
                                                            key: 'invite',
                                                            label: 'Invitations',
                                                            itemIcon: <UsergroupAddOutlined />,
                                                            onClick: () => {
                                                                this.setState({ invitesVisible: true })
                                                            },
                                                        },
                                                        {
                                                            type: 'divider',
                                                        },
                                                        {
                                                            type: 'group',
                                                            label: (
                                                                <>
                                                                    version <code>{conf.version}</code>
                                                                </>
                                                            ),
                                                        },
                                                    ],
                                                }}
                                            >
                                                <Button icon={<UserOutlined />} type={'default'} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                    <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        )}
                                    </UserProviderContext.Consumer>
                                    <ThemeContext.Consumer>
                                        {ctx => (
                                            <Button
                                                icon={<BulbOutlined />}
                                                type={'default'}
                                                onClick={() => {
                                                    ctx.toggleDarkMode()
                                                }}
                                            ></Button>
                                        )}
                                    </ThemeContext.Consumer>
                                </Space>
                            </Header>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props: RouteComponentProps<any>) => {
                                        return <HomePage apiService={this.props.apiService} routeComponentProps={props} />
                                    }}
                                ></Route>
                                <Route exact path="/Services">
                                    <ServiceList apiService={this.props.apiService} />
                                </Route>
                                <Route exact path="/Store">
                                    <StoreLayout apiService={this.props.apiService} />
                                </Route>
                                <Route exact path="/billing">
                                    <BillingAccountList apiService={this.props.apiService} />
                                </Route>
                                <Route
                                    path="/project/:projectId/:tab"
                                    render={(props: RouteComponentProps<{ projectId: string; tab: string }>) => {
                                        return <ProjectDetailLayout apiService={this.props.apiService} routeComponentProps={props} />
                                    }}
                                ></Route>
                                <Route
                                    path="/service/:serviceId"
                                    render={(props: RouteComponentProps<{ serviceId: string }>) => {
                                        return <ServiceDetailLayout apiService={this.props.apiService} routeComponentProps={props} />
                                    }}
                                ></Route>
                                <Route
                                    path="/store/:serviceId"
                                    render={(props: RouteComponentProps<{ serviceId: string }>) => {
                                        return <StoreServiceDetail apiService={this.props.apiService} routeComponentProps={props} />
                                    }}
                                ></Route>
                                <Route
                                    path="/billing/:billingAccountId"
                                    render={(props: RouteComponentProps<{ billingAccountId: string }>) => {
                                        return <BillingAccountDetail apiService={this.props.apiService} routeComponentProps={props} />
                                    }}
                                ></Route>
                            </Switch>
                        </Router>
                        {this.state.invitesVisible ? (
                            <InviteUserModal inviteModelClosed={this.inviteModelClosed} visible={this.state.invitesVisible} userInstance={this.props.userInstance} />
                        ) : null}
                        <UserSettingsModal visible={this.state.showSettings} cancelCallback={this.settingsCancelCallback} />
                        <UserProviderContext.Consumer>
                            {ctx =>
                                ctx.isRootProjectMember ? (
                                    <ChangeProjectSubscriptionModal
                                        visible={this.state.showChangeProjectSubscriptionSettings}
                                        cancelCallback={() => {
                                            this.setState({
                                                showChangeProjectSubscriptionSettings: false,
                                            })
                                        }}
                                        rootRbsSdk={this.props.apiService.rootRbsSdk}
                                    />
                                ) : null
                            }
                        </UserProviderContext.Consumer>
                    </Spin>
                </Layout>
            </>
        )
    }
}

MainLayout.contextType = UserProviderContext
export default withAntdToken(MainLayout)

import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Divider, Layout, message, notification, Row, Spin, Typography } from 'antd'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import logo from '../logo.svg'
import RecaptchaLogo from '../Assets/Images/RecaptchaLogo.svg'
import { Auth } from 'aws-amplify'
import SignInForm from '../Components/SignInForm'
import SignUpForm from '../Components/SignUpForm'
import ConfirmSignUpForm from '../Components/ConfirmSignUpForm'
import ForgotPasswordForm from '../Components/ForgotPasswordForm'
import ForgotPasswordSubmitForm from '../Components/ForgotPasswordSubmitForm'
import autoGenConfig from '../config/autoGenConfig'
import { BulbOutlined, LoadingOutlined } from '@ant-design/icons'
import { APIService, RootProjectClassEnums, RootProjectClassMethods } from '../Api/APIService'
import ConfirmSignInForm from '../Components/ConfirmSignInForm'
import { RetterCloudObject } from '@retter/sdk'
import ConfirmInvitationForm from '../Components/ConfirmInvitationForm'
import { ThemeContext } from '../Contexts/ThemeContext'
import { useLocation } from 'react-router-dom'

const { Content } = Layout
const spinIcon = <LoadingOutlined spin />

interface Props {
    apiService: APIService
}

const WelcomeLayout = (props: Props) => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    let [userInstance, setUserInstance] = useState<RetterCloudObject>()
    let [loading, setLoading] = useState(false)
    let [formState, setFormState] = useState('login')
    let [username, setUsername] = useState('')
    let [password, setPassword] = useState('')
    let [forgotPasswordMail, setForgotPasswordMail] = useState('')

    const navigateTo = (type: string) => {
        setFormState(type)
    }

    const onSignInPressed = async (values: { email: string }) => {
        try {
            setLoading(true)
            const captcha_token = autoGenConfig.captchaKey ? await executeRecaptcha('sendOtp') : undefined

            const userObject = await props.apiService.rootRbsSdk.getCloudObject({
                classId: RootProjectClassEnums.EmailAuthenticator,
                body: {
                    email: values.email,
                },
            })
            const result = await userObject.call<any>({
                method: RootProjectClassMethods.sendEmailOtp,
                body: {
                    captcha: captcha_token,
                },
            })
            setUserInstance(userObject)
            setLoading(false)
            if (result && result.data.loginStatus === 'OTP') {
                navigateTo('confirmSignIn')
            } else if (result && result.data.loginStatus === 'INVITE') {
                navigateTo('confirmInvitation')
            }
        } catch (e: any) {
            setLoading(false)
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message,
                })
            } else if (e.code === 'UserNotConfirmedException') {
                navigateTo('confirmSignIn')
                return
            } else {
                message.error(e.message)
            }
        }
    }

    const onConfirmSignIn = async (otp: string) => {
        if (!userInstance) throw new Error('user instance is null')
        setLoading(true)
        try {
            const captcha_token = autoGenConfig.captchaKey ? await executeRecaptcha('confirmOtp') : undefined
            const result = await userInstance.call<any>({
                method: RootProjectClassMethods.validateEmailOtp,
                body: {
                    otp,
                    captcha: captcha_token,
                },
            })

            await props.apiService.rootRbsSdk.authenticateWithCustomToken(result.data.customToken)
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message,
                })
            }
        }
        setLoading(false)
    }

    const onInvitationConfirmPressed = async (code: string) => {
        if (!userInstance) throw new Error('user instance is null')
        setLoading(true)
        try {
            const captcha_token = autoGenConfig.captchaKey ? await executeRecaptcha('confirmInvitation') : undefined
            const response = await userInstance.call<any>({
                method: RootProjectClassMethods.invitationConfirm,
                body: {
                    code,
                    captcha: captcha_token,
                },
            })
            await props.apiService.rootRbsSdk.authenticateWithCustomToken(response.data.customToken)
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.message) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message,
                })
            } else if (e.code === 'UserNotConfirmedException') {
                navigateTo('confirmSignIn')
                return
            } else {
                message.error(e.message)
            }
        }
        setLoading(false)
    }

    const onSignUpPressed = async (values: any) => {
        setLoading(true)
        const token = await executeRecaptcha('register')
        setUsername(values.email)
        setPassword(values.password)

        const captchaObject: any = {
            Name: 'recaptchaToken',
            Value: token,
        }

        const captchaSiteKey: any = {
            Name: 'siteKey',
            Value: autoGenConfig.captchaKey || '',
        }

        try {
            const response = await Auth.signUp({
                username: values.email,
                password: values.password,
                validationData: [captchaObject, captchaSiteKey],
            })
            setLoading(false)
            if (response.user) {
                navigateTo('confirmSignUp')
            }
        } catch (err) {
            setLoading(false)
        }
    }

    const onConfirmSignUpPressed = async ({ email, code, password }: any) => {
        try {
            setLoading(true)
            await Auth.confirmSignUp(email, code, password)
            setLoading(false)
            navigateTo('login')
        } catch (err) {
            setLoading(false)
            message.error(err.message)
        }
    }

    const onForgotPasswordPressed = async (email: string) => {
        setLoading(true)
        setForgotPasswordMail(email)
        try {
            const response = await Auth.forgotPassword(email)
            setLoading(false)
            if (response.CodeDeliveryDetails) {
                navigateTo('forgotPasswordSubmit')
            }
        } catch (err) {
            setLoading(false)
            message.error(err.message)
            // captchaRef?.current?.reset();
        }
    }

    const onForgotPasswordSubmitPressed = async ({ email, code, password }: any) => {
        try {
            setLoading(true)
            await Auth.forgotPasswordSubmit(email, code, password)
            setLoading(false)
            navigateTo('login')
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // Get the query string from the URL
                const queryString = window.location.search

                // Parse the query string
                const queryParams = new URLSearchParams(queryString)

                if (queryParams.has('customToken')) {
                    setLoading(true)
                    const customToken = queryParams.get('customToken')
                    if (customToken) {
                        const res = await props.apiService.rootRbsSdk.authenticateWithCustomToken(customToken)
                        if (res.authStatus === 'SIGNED_IN') {
                            notification.success({
                                message: 'Login Successful',
                            })
                        } else if (res.authStatus === 'AUTH_FAILED') {
                            notification.error({
                                message: 'Login Failed',
                            })
                        }
                        window.history.replaceState({}, document.title, window.location.pathname)
                    }
                    setLoading(false)
                }
                // clear the query string
            } catch (e) {
                navigateTo('login')
            }
        }
        checkAuth()
    }, [])

    return (
        <Layout style={{ height: '100%' }}>
            <ThemeContext.Consumer>
                {(ctx) => (
                    <Button
                        icon={<BulbOutlined />}
                        type={'default'}
                        onClick={() => {
                            ctx.toggleDarkMode()
                        }}
                        style={{ position: 'absolute', top: 10, right: 10 }}
                    />
                )}
            </ThemeContext.Consumer>
            <Row justify="center" align="middle" style={{ minHeight: '90vh' }}>
                <Col xs={{ span: 16 }} lg={{ span: 10 }} style={{ maxWidth: '450px' }}>
                    <Spin spinning={loading} indicator={<Spin indicator={spinIcon} />}>
                        <Content style={{ overflow: 'initial' }}>
                            <div className={'site-layout-background'} style={{ padding: 24, textAlign: 'center' }}>
                                <img src={logo} style={{ width: '15em' }} alt={'RTBS'} />
                                <Divider />
                                {formState === 'login' && (
                                    <>
                                        <SignInForm
                                            loading={loading}
                                            onSignInPressed={onSignInPressed}
                                            onSignUpPressed={() => navigateTo('register')}
                                            onForgotPasswordPressed={() => navigateTo('forgotPassword')}
                                        />
                                        <CardComponent />
                                    </>
                                )}
                                {formState === 'register' && <SignUpForm loading={loading} onSignUpPressed={onSignUpPressed} onSignInPressed={() => navigateTo('login')} />}
                                {formState === 'confirmSignUp' && (
                                    <ConfirmSignUpForm
                                        loading={loading}
                                        onConfirmSignUpPressed={onConfirmSignUpPressed}
                                        onSignInPressed={() => navigateTo('login')}
                                        username={username}
                                        password={password}
                                    />
                                )}
                                {formState === 'forgotPassword' && (
                                    <ForgotPasswordForm loading={loading} onForgotPasswordPressed={onForgotPasswordPressed} onSignInPressed={() => navigateTo('login')} />
                                )}
                                {formState === 'forgotPasswordSubmit' && (
                                    <ForgotPasswordSubmitForm
                                        loading={loading}
                                        onForgotPasswordSubmitPressed={onForgotPasswordSubmitPressed}
                                        onSignInPressed={() => navigateTo('login')}
                                        username={forgotPasswordMail}
                                    />
                                )}
                                {formState === 'confirmSignIn' && (
                                    <>
                                        <ConfirmSignInForm
                                            onConfirmPressed={onConfirmSignIn}
                                            apiService={props.apiService}
                                            loading={loading}
                                            onSignInPressed={() => navigateTo('login')}
                                            userInstance={userInstance}
                                        />
                                        <CardComponent />
                                    </>
                                )}
                                {formState === 'confirmInvitation' && (
                                    <>
                                        <ConfirmInvitationForm
                                            apiService={props.apiService}
                                            loading={loading}
                                            onInvitationConfirmPressed={onInvitationConfirmPressed}
                                            onSignInPressed={() => navigateTo('login')}
                                        />
                                        <CardComponent />
                                    </>
                                )}
                            </div>
                        </Content>
                    </Spin>
                </Col>
            </Row>
        </Layout>
    )
}

export default WelcomeLayout

const CardComponent = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <img
            src={RecaptchaLogo}
            style={{ width: '2.5em', cursor: 'pointer', marginRight: '10px' }}
            alt={'RTBS'}
            onClick={() => {
                window.open('https://policies.google.com/?hl=tr')
            }}
        />
        <Typography style={{ fontSize: 12 }}>Verified with reCAPTCHA</Typography>
    </div>
)
